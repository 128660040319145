import { useContext } from 'react';

import { DialogContext } from '../DialogContext';

/*
  Use this hook instead of directly calling context in components
  for easier testing of the dialog context
*/
export function useDialogContext() {
  return useContext(DialogContext);
}
